import _ from "lodash"
import React, { useState, useEffect, useRef, Fragment } from "react"
import cards from "card-validator"

export const validateLength = (minSize, maxSize, value) => {
  const len = _.size(value)
  if (len < minSize) {
    return {
      error: `A minimum of ${minSize} characters are required`,
      value,
    }
  }
  if (len > maxSize) {
    return {
      value: (value || "").slice(0, maxSize),
    }
  }
  return {
    value,
  }
}

export const validateIsValue = (desiredValue, value) => {
  if (desiredValue === value) {
    return {
      error: undefined,
      value,
    }
  }
  return {
    error: "Values don't match",
    value: value,
  }
}

export const validateHasValue = value => {
  if (_.size(value) > 0) {
    return {
      error: undefined,
      value,
    }
  }
  return {
    error: "Missing Value",
    value: value,
  }
}

export const validateNone = value => ({
  error: undefined,
  value: value,
})

export const validatePhone = value => {
  const x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  const newValue = !x[2] ? x[1] : x[1] + "-" + x[2] + (x[3] ? "-" + x[3] : "")
  return {
    error: undefined,
    value: newValue,
  }
}

export const validateCreditCardNumber = value => {
  const cardValid = cards.number(value)
  // console.log({cardValid, value});
  return {
    error: cardValid.isValid ? undefined : "Invalid credit card number",
    value: value,
  }
}

export const validateExpirationDate = value => {
  const expValid = cards.expirationDate(value)
  // console.log({expValid});
  return {
    error: expValid.isValid ? undefined : "Invalid exp. date",
    value: value,
    returnValue: _.pick(expValid, ["month", "year"]),
  }
}

export const validateCVV = value => {
  const cvvValid = cards.cvv(value)
  return {
    error: cvvValid.isValid ? undefined : "Invalid CVV",
    value: value,
  }
}

const TextInput = ({
  labelText,
  placeholderText = "",
  defaultValue = "",
  validator = validateNone,
  validationVisible = false,
  type = "text",
  required,
  validationPattern,
  helpText = "",
  readOnly,
}) => {
  const inputRef = useRef()
  const [state, setState] = useState(validator(defaultValue))

  const onChange = event => {
    const value = _.get(event, "target.value")
    // console.log({value}, "changed");

    setState(validator(value))
  }

  const setValue = value => setState(validator(value || ""))

  const validationRendered = validationVisible ? (
    <p className="input--helptext__error" role="alert">
      {state.error ? `${state.error}` : " "}
    </p>
  ) : (
    undefined
  )

  const rendered = (
    <Fragment>
      <label
        className={
          validationVisible && state.error ? "input__error" : undefined
        }
      >
        {labelText}
        {validationRendered}
        <input
          ref={inputRef}
          type={type}
          value={state.value}
          onChange={onChange}
          placeholder={placeholderText.length > 0 ? placeholderText : labelText}
          required={required}
          pattern={validationPattern}
          readOnly={readOnly}
        />
      </label>
      {helpText && <p className="input--helptext">{helpText}</p>}
    </Fragment>
  )

  const returnValue = state.returnValue ? state.returnValue : state.value

  // console.log({state});
  const validatedResult = _.isUndefined(state.error) ? returnValue : undefined
  return [validatedResult, rendered, setValue]
}

export default TextInput
