import _ from 'lodash';
import Auth from '@aws-amplify/auth'
import store from 'store';

const USER_STORAGE_KEY = 'acornUser';

const isBrowser = typeof window !== `undefined`

// export const setUserLocalstorage = user => (window.localStorage.acornUser = JSON.stringify(user))

export const transformUser = (user) => {
  user = user || {};

  // console.log({user});
  return {
    username: user.username,
    isAdmin: _.includes(_.get(user, ['signInUserSession', 'accessToken', 'payload', 'cognito:groups']) || [], 'admin'),
  };
}

export const getUser = async () => {
  try {
    // const session = await Auth.currentSession();
    // console.log({session}, 'got session');
    // debugger;

    // const result = await Auth.currentCredentials();
    // console.log({result}, 'got ');

    const user = await Auth.currentAuthenticatedUser();
    const transformedUser = transformUser(user);

    store.set(USER_STORAGE_KEY, transformedUser);

    return transformedUser;
  } catch(err) {
      // console.error({getUserError: err}, 'caught error in getUser');
      // throw err;
      return undefined;
  }
}

export const getIdentityId = async () => {
  const credentials = await Auth.currentCredentials();
  return _.get(credentials, 'params.IdentityId');
};

export const logout = () => store.remove(USER_STORAGE_KEY);

export const getUserFromLocalstorage = () => store.get(USER_STORAGE_KEY); 

// export const isLoggedInFromLocalstorage = () => !!store.get('User').username;

export const getCurrentUser = () => isBrowser && getUser();

export const isAdminFromLocalstorage = () => {
  try {
    return store.get(USER_STORAGE_KEY).isAdmin;
  } catch (err) {
    return false;
  }
};

export const clearLocalstorage = () => {
  if (isBrowser) {
    window.localStorage.acornUser = undefined;
  }
};