import _ from 'lodash';
import React, { useState } from 'react';

const CheckboxInput = ({labelText, required, defaultValue = false, key}) => {
    const [checked, setChecked] = useState(defaultValue);

    const setValue = (newChecked) => setChecked(newChecked);
    const onChange = () => setChecked(!checked);

    const rendered = <label key={key}>
        <input
            type='checkbox'
            checked={checked}
            onChange={onChange}
            required={required}
        />
        {labelText}
    </label>;
    return [checked, rendered, setValue];
};

export default CheckboxInput;