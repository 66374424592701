/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import _ from 'lodash'
import "../scss/index.scss"
import "../images/foundation-icons/foundation-icons.css"
import Banner from "./Banner"
import Footer from "./Footer"
import { UserProvider } from "./UserContext"
import { CartProvider } from "./CartContext"

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <CartProvider CartInitialState={_.get(location, 'state.Cart')}>
            <UserProvider>
              <Banner siteTitle={data.site.siteMetadata.title} />
              <main className="grid-container">{children}</main>
              <Footer />
            </UserProvider>
          </CartProvider>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
