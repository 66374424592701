import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import User from "./user/User"

const Banner = () => (
  <div className="site-banner">
    <div className="grid-container">
      <div className="top-bar">
        <div className="top-bar-left">
          <ul className="menu" role="navigation">
            <li className="site-tab mvg">
              <a href="https://mountainvalleygrowers.com">
                Mountain Valley Growers
              </a>
            </li>
            <li className="current-item site-tab plugtrays">
              <Link to="/">Organic Plug Trays</Link>
            </li>
          </ul>
        </div>
        <div className="top-bar-right">
          <User />
        </div>
      </div>
    </div>
  </div>
)

export default Banner
