import _ from 'lodash';
import React, { useState, useEffect, useContext, useRef } from "react"
import { graphqlOperation, API } from "aws-amplify"
import { checkout } from "../../graphql/cart"
import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { CartContext } from "../CartContext"
import { formatCurrency } from "../../utils/cart"
import CartCheckoutShippingAddress from "./CartCheckoutShippingAddress"
import CartCheckoutShipping from "./CartCheckoutShipping"
import CartCheckoutBilling from "./CartCheckoutBilling"
import CartCheckoutPayment from "./CartCheckoutPayment"
import { Link } from "gatsby"

const CartBannerWidget = () => {
  const { Cart, WidgetVisible, ShowWidget, SetWidgetVisible } = useContext(CartContext)
  const { PackageCount, PlugtrayCount, Subtotal, ProductsHydrated } = Cart
  const wrapper = useRef(null)


  const handleClick = event => SetWidgetVisible(!WidgetVisible);
  const handleClickOutside = event => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      // console.log("got click outside!!");
      SetWidgetVisible(false);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
  const renderProduct = ({ Id, CommonName, BotanicalName, cartQuantity }) => (
    <li key={Id} className="minicart--product grid-x">
      <p className="cell small-12 medium-10">
        <em>{BotanicalName}</em>
        <br />
        {CommonName}
      </p>
      <p className="minicart--product-quantity cell small-12 medium-2">
        <strong>x{cartQuantity}</strong>
      </p>
    </li>
  )

  return (
    <li key="cartWidget" ref={wrapper}>
      <a
        className={`menu toggle ${WidgetVisible ? "open" : "closed"}`}
        aria-controls="minicart"
        onClick={handleClick}
      >
        <i className="fi-shopping-cart" /> Your cart
      </a>
      <ul className="submenu" id="minicart" aria-hidden={!WidgetVisible}>
        {PlugtrayCount === 0 ? (
          <p>Your cart is empty!</p>
        ) : (
            <>
              {_.map(ProductsHydrated, renderProduct)}
              <li className="minicart--summary">
                <p className="minicart--subtotal">
                  Subtotal <span>{formatCurrency(Subtotal)}</span>
                </p>
                {PlugtrayCount % 2 === 1 && (
                  <p className="minicart--plugtray-count">
                    Get free shipping on your next plug tray!
                </p>
                )}
                <Link
                  to="/app/cart"
                  className="button secondary minicart--checkout-button"
                >
                  View cart and check out
              </Link>
              </li>
            </>
          )}
      </ul>
    </li>
  )
}

export default CartBannerWidget
