import _ from "lodash"
import React, { useState, useEffect, useContext } from "react"
import { graphqlOperation, API } from "aws-amplify"
import { saveBillingPayment, checkout } from "../../graphql/cart"

import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { CartContext } from "../CartContext"
import { formatCurrency } from "../../utils/cart"
import CartCheckoutPayment from "./CartCheckoutPayment"
import CartCheckoutCreateAccount from "./CartCheckoutCreateAccount"
import CartCheckoutBilling from "./CartCheckoutBilling"
import SEO from "../seo"
import { Link } from "gatsby"
import CartCheckoutOrderSummary from "./CartCheckoutOrderSummary"
import UserInfoSummary from "./CartUserInfoSummary"
import Button from "../Button"
import { serviceCodeLookup } from "../../utils/cart"
import {getMyOrders} from '../../graphql/users';

export const useCheckoutContext = ({location}, CartContextValue) => {
    const [checkoutPayload, setCheckoutPayload] = useState({loading: false, data: {}});
  
    useEffect(() => {
      const f = async () => {
        const cartInState = _.get(location,"state.Cart");
        if (cartInState) {
          console.log({state: location.state}, 'using Checkout data from state');
          setCheckoutPayload({loading: false, data: location.state});;
        } else {
          // Call checkout & load real cart
          // console.log('need to load the real CART BY CALLING CHECKOUT!');
          const result = await API.graphql(
            graphqlOperation(checkout, {Review: true})
          );
          // console.log({ result }, "finished loading cart");
          setCheckoutPayload({loading: false, data: result.data.Checkout});
          console.log('loaded Checkout payload from API');
        }
      };
      f();
    }, [location]);
  
    return checkoutPayload;
  };

  export const useMyOrders = () => {
    const [myOrders, setMyOrders] = useState({loading: false, data: []});

    useEffect(() => {
      const f = async () => {
        const myOrders = await API.graphql(graphqlOperation(getMyOrders));
        // console.log({myOrders}, 'got orders!');
        setMyOrders({loading: false, data: _.sortBy(myOrders.data.GetMyOrders, ['TimestampCreated'])});
      };
      f();
    }, []);
    return myOrders;
  };