import React, { useState, useEffect, createContext } from 'react';
import { getCart, saveProducts } from '../graphql/cart';

import { graphqlOperation, API } from 'aws-amplify';
import { hydrateCartProductsWithFn, calculateOrderDetails } from '../utils/cart';
import _ from 'lodash';

const CartContext = createContext([{}, () => { }]);

const CartProvider = (props) => {
    const [CartProducts, SetCartProducts] = useState({});       // Canonical representation of user cart
    const [WidgetVisible, SetWidgetVisible] = useState(false);
    const [Cart, SetCart] = useState({});                // Hydrated representation
    const [CheckoutClauses, SetCheckoutClauses] = useState({});

    const { CartInitialState } = props;

    const ShowWidget = () => {
        console.log('showing widget');
        SetWidgetVisible(true);
        setTimeout(() => {
            console.log('hiding widget');
            SetWidgetVisible(false);
        }, 5000);
    };

    const setCartProductQuantity = async (Id, Quantity) => {
        const newCartProducts = { ...CartProducts };
        if (Quantity < 0) {
            throw new Error('quantity must be >= 0, refusing to set a negative quantity');
        }
        if (Quantity === 0) {
            delete newCartProducts[Id];
        } else {
            _.set(newCartProducts, Id, Quantity);
        }

        const allProducts = _.map(newCartProducts, (Quantity, Id) => ({ Quantity, Id }));
        const Products = _.filter(allProducts, ({ Quantity }) => Quantity > 0);
        const savePayload = { Products };
        const result = await API.graphql(graphqlOperation(saveProducts, savePayload));
        SetCartProducts(newCartProducts);
        ShowWidget();
    }

    const clearCartProducts = async () => {
        console.log('clearing cart products!');
        const savePayload = { Products: {} };
        // const result = await API.graphql(graphqlOperation(saveProducts, savePayload));
        SetCartProducts({});
    };

    const initWithCart = (cart, InitSource) => {
        let cartProducts = {};
        _.map(_.get(cart, 'Products'), ({ Id, Quantity }) => _.set(cartProducts, Id, Quantity));
        SetCartProducts(cartProducts);
        SetCheckoutClauses({ ..._.pick(cart, ['Shipping', 'ShippingService', 'Billing']), InitSource });
    };
    useEffect(() => {
        const f = async () => {
            if (CartInitialState) {
                console.log({ CartInitialState }, 'found cart state from location state, using it!');
                initWithCart(CartInitialState, 'STATE');
            } else {
                console.log('loading cart, couldnt find it in location state');
                const result = await API.graphql(graphqlOperation(getCart));
                initWithCart(_.get(result, 'data.GetCart'), 'API');
            }
        };
        f();
    }, [CartInitialState]);

    useEffect(() => {
        const f = async () => {
            const ProductsHydrated = await hydrateCartProductsWithFn(CartProducts, (quantity, key) => ({ setCartQuantity: _.partial(setCartProductQuantity, key) }));

            const {
                plugtrayCount,
                packageCount,
                subtotal,
            } = calculateOrderDetails(ProductsHydrated);

            const newCart = {
                // ..._.omit(result.data.GetCart, 'Products'),
                ProductsHydrated,
                CartProducts,
                Subtotal: subtotal,
                PackageCount: packageCount,
                PlugtrayCount: plugtrayCount,
                setCartProductQuantity,
                clearCartProducts,
            };
            // console.log({newCart}, 'setting new state!');
            SetCart(newCart);
        };
        f();
    }, [CartProducts]);


    return (
        <CartContext.Provider value={{ Cart, CheckoutClauses, ShowWidget, WidgetVisible, SetWidgetVisible }}>
            {props.children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };