import _ from "lodash"
import { Link } from "gatsby"
import React, { useState, useEffect, useRef, useContext } from "react"
import { graphqlOperation, API } from "aws-amplify"
import Modal from "react-modal"
import TextInput, {
  validateNone,
  validateLength,
  validatePhone,
} from "../TextInput"
import ShippingServiceInput from "./ShippingServiceInput"
import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { saveShipping, getCart, validateAddress } from "../../graphql/cart"
import CheckboxInput from "../CheckboxInput"
import { CartContext } from "../CartContext"
import StateInput from "../StateInput"
import { fetchWarrantyStates } from "../../utils/warranty-states"
import CartCheckoutOrderSummary from "./CartCheckoutOrderSummary"
import UserInfoSummary from "./CartUserInfoSummary"
import Button from "../Button"
import { useCheckoutContext } from './hooks'

const renderAddress = ({
  FirstName,
  LastName,
  Address,
  Address2,
  City,
  State,
  Zip,
  Phone,
  Email,
}) => (
    <div>
      <p>
        {FirstName} {LastName}
      </p>
      <p>{Address}</p>
      <p>{Address2 ? Address2 : ""}</p>
      <p>
        {City}, {State} {Zip}
      </p>
      <p>
        <i className="fi-telephone"></i> {Phone}
      </p>
      <p>
        <i className="fi-mail"></i> {Email}
      </p>
    </div>
  )

const CartCheckoutShipping = ({ location }) => {
  const checkoutState = useCheckoutContext({ location });
  const [submitState, setSubmitState] = useState({ submitting: false });
  // console.log({checkoutState: checkoutState.data});

  const { Cart, CheckoutClauses } = useContext(CartContext)
  const { PlugtrayCount } = Cart

  // console.log({CheckoutClauses}, 'got cart!');
  // console.log({location});
  const [address, setAddress] = useState({})
  const [submitError, setSubmitError] = useState(undefined)
  const [warrantyStates, SetWarrantyStates] = useState({
    loading: true,
    data: [],
  })
  const [validationVisible, setValidationVisible] = useState(false)

  const { FirstName, LastName, Address, Address2, City, State, Zip } = address

  const [
    shippingService,
    shippingServiceRendered,
    setShippingService,
  ] = ShippingServiceInput({
    validationVisible,
    PlugtrayCount,
    FirstName,
    LastName,
    Address,
    Address2,
    City,
    State,
    Zip,
  })

  const [
    DeliveryInstructions,
    DeliveryInstructionsRendered,
    SetDeliveryInstructions,
  ] = TextInput({
    validationVisible,
    validator: _.partial(validateLength, 0, 25),
    labelText: "UPS delivery instructions (optional, limit 25 characters)",
    helpText: "Example: Leave on porch in shade",
  })

  const [
    WaiveWarranty,
    WaiveWarrantyRendered,
    SetWaiveWarranty,
  ] = CheckboxInput({
    validationVisible,
    required: true,
    labelText:
      "I will waive my warranty and understand safe delivery cannot be guaranteed.",
  })

  let warrantyRendered = <i>Loading........</i>
  if (_.includes(warrantyStates.data, State)) {
    warrantyRendered = (
      <p className="callout primary cell small-12">
        Your plants are covered by our 7-day shipping warranty.
      </p>
    )
  } else if (!warrantyStates.loading) {
    warrantyRendered = (
      <div className="cell small-12 callout alert waive-warranty">
        <p>
          <strong>
            Your area or the area your plants must travel through is too cold to
            guarantee their safe arrival.
          </strong>
        </p>
        <p>
          If you place your order now, our usual shipping warranty will not
          apply. This means that we cannot refund plants damaged by the cold if
          you choose to have them sent now.
        </p>
        <p>
          Please note that we also cannot hold plug trays for later ship dates
          because plants outgrow plug trays very quickly.
        </p>

        {WaiveWarrantyRendered}
      </div>
    )
  }
  useEffect(() => {
    const f = async () => {
      const shipping = _.get(checkoutState, "data.Cart.Shipping")
      const shippingService = _.get(checkoutState, "data.Cart.ShippingService") || {}

      if (!_.isEmpty(shipping)) {
        setAddress(shipping)
      }
      if (!_.isEmpty(shippingService)) {
        SetDeliveryInstructions(shippingService.DeliveryInstructions)
        SetWaiveWarranty(shippingService.WaiveWarranty || false)
      }

      const warrantyStates = await fetchWarrantyStates()
      SetWarrantyStates({ loading: false, data: warrantyStates.WarrantyStates })
    }
    f()
  }, [checkoutState.data])

  const submit = async event => {
    event.preventDefault()
    const fieldValues = [shippingService, DeliveryInstructions]
    let passedValidation = true
    _.map(fieldValues, value => {
      if (_.isUndefined(value)) {
        passedValidation = false
      }
    })
    if (!passedValidation) {
      setValidationVisible(true)

      return
    }

    const shippingPayload = {
      ...shippingService,
      DeliveryInstructions,
      WaiveWarranty,
    }

    try {
      setSubmitState({ submitting: true });
      const result = await API.graphql(
        graphqlOperation(saveShipping, shippingPayload)
      )
      setSubmitState({ submitting: false });
      console.log({ result }, "saved shipping information!")
      const Cart = _.get(result, "data.SaveCart")
      navigate("/app/checkout/billing-payment", { state: result.data.Checkout })

    } catch (err) {
      const errMsgs = _.map(_.get(err, "errors"), ({ message }) => message)
      setSubmitError(errMsgs.join(", "))
      setSubmitState({ submitting: false });

      console.error({ err }, "got error!")
      // TODO: render error, let user try again
    }
    // navigate('/app/checkout/payment');
  }

  const shippingRendered = _.get(CheckoutClauses, "Shipping") ? (
    renderAddress(CheckoutClauses.Shipping)
  ) : (
      <p>Loading.....</p>
    )

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 medium-8">
        <UserInfoSummary
          heading="Shipping information"
          editLink="/app/checkout/shipping-address"
          infoRendered={shippingRendered}
        />
        <form onSubmit={submit}>
          <fieldset className="grid-x grid-padding-x fieldset--center-legend">
            <legend className="header__small-caps">Shipping options</legend>
            {shippingServiceRendered}
            <div className="cell small-12">
              <p>
                <strong>Delivery instructions (optional)</strong>
              </p>
              <p>
                If your UPS delivery driver needs special instructions, such as
                a gate code, enter that information here.
              </p>
            </div>
            <div className="cell small-12 medium-7">
              {DeliveryInstructionsRendered}
            </div>
            {warrantyRendered}

            <Button
              type="submit"
              isDisabled={submitState.submitting}
              style={submitState.submitting ? 'primary expanded loading' : "primary expanded"}
              text="Continue to Billing"
            />
            <br />
            {submitError}
          </fieldset>
        </form>
      </div>
      <div className="cell small-12 medium-4">
        <CartCheckoutOrderSummary
          ShippingPrice={
            _.get(
              checkoutState,
              "data.Cart.ShippingService.shippingService.Price"
            ) || 0
          }
          Subtotal={_.get(checkoutState, "data.Subtotal") || 0}
          Tax={_.get(checkoutState, "data.Tax") || 0}
          Total={_.get(checkoutState, "data.Total") || 0}
        />
      </div>
    </div>
  )
}

export default CartCheckoutShipping
