import { Link } from "gatsby";
import React from "react";
import usdaLogoPath from '../images/usda-logo-reverse.png';
import logo1x from "../images/logo@1x-reverse.png"
import logo2x from "../images/logo@2x-reverse.png"

const Footer = () => (
  <footer>
    <div className="grid-container">
      <div className="grid-x">
        <div className="cell small-6 footer--logo-container">
          <img src={usdaLogoPath}
            alt="USDA certified organic logo"
            width="100"
          />
          <img src={logo1x}
            srcSet={`${logo2x} 2x`}
            alt="Plug trays from Mountain Valley Growers logo"
            width="200"
          />
        </div>
        <div className="cell small-6">
          <div className="footer--social">
            <a href="https://www.pinterest.com/mvgorganic/" target="blank" rel="noopener noreferrer">
              <i className="fi-social-pinterest" alt="Visit Mountain Valley Growers on Instagram" />
            </a>
            <a href="https://www.instagram.com/mountainvalleygrowers/" target="blank" rel="noopener noreferrer">
              <i className="fi-social-instagram" alt="Visit Mountain Valley Growers on Pinterest" />
            </a>
          </div>
          <h4 className="header__small-caps">Resources</h4>
          <ul>
            <li>
              <Link to="/faq">
                Frequently asked questions
              </Link>
            </li>
            <li>
              <a href="https://www.mountainvalleygrowers.com/organiccert.htm" target="blank" rel="noopener noreferrer">
                Organic certification
              </a>
            </li>
            <li>
              <a href="mailto:customerservice@mountainvalleygrowers.com">
                Contact us
              </a>
            </li>
            <li>
              <a href="https://www.mountainvalleygrowers.com" target="blank" rel="noopener noreferrer">
                Shop 3-inch pots at Mountain Valley Growers
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <div className="footer--copyright">
      © 2014 - {new Date().getFullYear()}, Mountain Valley Growers, Inc.
    </div>
  </footer>
);

export default Footer;