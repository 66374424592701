import React, { useState, useEffect, useContext, Component } from "react"
import Modal from "react-modal"
import { Authenticator, SignIn } from "aws-amplify-react"
import { navigate } from "@reach/router"
import { loginMergeIdentity } from "../../graphql/cart"
import { graphqlOperation, API, I18n } from "aws-amplify"
import store from "store"
import { UserContext } from "../UserContext"

const GUEST_IDENTITY_STORAGE_KEY = "guestIdentityId"

const formatAuthenticatorError = (message) => {
  if (message === 'Custom auth lambda trigger is not configured for the user pool.') {
    return 'Password cannot be empty';
  }
  // console.log({message}, 'got auth error');
  return message;
};

// variables from https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-react/src/Amplify-UI/Amplify-UI-Theme.tsx
const plugTraysTheme = {
  container: { fontSize: "100%" },
  formContainer: { marginTop: "7%" },
  formSection: {
    boxShadow: "none",
    padding: 0,
    marginTop: "1rem",
    borderRadius: 0,
    borderTop: "1px solid #ebebe5",
  },
  sectionHeader: {
    textAlign: "center",
    fontFamily: "Poppins Semibold, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
    letterSpacing: "0.05rem",
    fontSize: "80%",
    marginTop: "-0.6rem",
  },
  sectionHeaderContent: { backgroundColor: "white", padding: "0 1rem" },
  signInButton: { display: "none" },
  strike: { display: "none" },
  button: { backgroundColor: "#896e7e", width: "100%" },
  inputLabel: { fontFamily: "Poppins Semibold, Helvetica, Arial, sans-serif" },
  input: { borderRadius: 0, border: "1px solid #cacaca", fontWeight: "bold" },
  sectionFooter: { display: "block" },
  sectionFooterPrimaryContent: {
    display: "block",
    width: "100%",
    textAlign: "center",
    marginBottom: "1rem",
  },
  sectionFooterSecondaryContent: {
    display: "block",
    width: "100%",
    textAlign: "center",
  },
  hint: { color: "#767676", fontSize: "13px" },
  a: { color: "#1b1428", borderBottom: "3px solid #8cbfbc" },
  toast: { backgroundColor: "#ffe5dd", color: "black", marginBottom: "2rem" },
}

const authScreenLabels = {
  en: {
    Email: "Username (your email address)",
    "Forget your password?": "Forgot your password?",
    "Sign in to your account": "Sign in",
    "Sign Up": "Create new account",
    "Sign Up Account": "Create a new account",
    "Back to Sign In": "Return to sign in",
    "Resend Code": "Email me another code",
  },
}

I18n.setLanguage("en")
I18n.putVocabularies(authScreenLabels)

const modalStyles = {
  content: {
    position: "relative",
    marginTop: "3rem",
  },
}

const LoginModal = () => {
  const { User, refreshUser } = useContext(UserContext);

  const [isOpen, setIsOpen] = useState(false)

  // const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false)

  const handleLogin = async () => {
    setIsOpen(false);
    const IdentityId = store.get(GUEST_IDENTITY_STORAGE_KEY)
    // console.log({IdentityId}, 'got guest identity id');
    const result = await API.graphql(
      graphqlOperation(loginMergeIdentity, { UserId: IdentityId })
    )
    console.log({result}, 'merged successfully!');
    refreshUser();
  }

  const signUpConfig = {
    hideAllDefaults: true,
    signUpFields: [
      {
        label: "Email",
        key: "username",
        required: true,
        displayOrder: 1,
        type: "email",
        custom: false,
      },
      {
        label: "Password",
        key: "password",
        required: true,
        displayOrder: 2,
        type: "password",
        custom: false,
      },
    ],
  }

  const rendered = (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Login"
      ariaHideApp={false}
      style={modalStyles}
    >
      <button className="button transparent modal--close" onClick={closeModal}>
        <i className="fi-x" /> close
      </button>
      <Authenticator
        errorMessage={formatAuthenticatorError}
        theme={plugTraysTheme}
        usernameAttributes="email"
        signUpConfig={signUpConfig}
        onStateChange={authState => {
          if (authState === "signedIn") {
            // setIsOpen(false)
            handleLogin()
          } else if (authState === "signIn") {
            // logout();
          }
        }}
      />
    </Modal>
  )

  return [rendered, setIsOpen]
}

export default LoginModal
