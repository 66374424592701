import _ from "lodash"
import React, { useState, useEffect, useRef, useContext, Fragment } from "react"
import { graphqlOperation, API } from "aws-amplify"
import Modal from "react-modal"
import TextInput, {
  validateNone,
  validateLength,
  validatePhone,
} from "../TextInput"
import ShippingServiceInput from "./ShippingServiceInput"
import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import {
  saveShippingAddress,
  getCart,
  validateAddress,
  checkout,
} from "../../graphql/cart"
import { getMyOrders } from "../../graphql/users"
import CheckboxInput from "../CheckboxInput"
import { CartContext } from "../CartContext"
import StateInput from "../StateInput"
import { fetchWarrantyStates } from "../../utils/warranty-states"
import Button from "../Button"
import CartCheckoutOrderSummary from "./CartCheckoutOrderSummary"
import { useMyOrders } from "./hooks"

const CartCheckoutShippingAddress = () => {
  const [addressSubmitState, setAddressSubmitState] = useState({
    submitting: false,
  })
  const [submitState, setSubmitState] = useState({ submitting: false })
  const [addressAsEntered, setAddressAsEntered] = useState(undefined)
  const { Cart } = useContext(CartContext)
  const { PlugtrayCount } = Cart
  const [CheckoutData, SetCheckoutData] = useState(undefined)
  const [chosenAddress, setChosenAddress] = useState(undefined)
  const [hidePrevAddress, setHidePrevAddress] = useState(undefined)

  const [submitError, setSubmitError] = useState(undefined)
  const [warrantyStates, SetWarrantyStates] = useState({
    loading: true,
    data: [],
  })
  const [validationVisible, setValidationVisible] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }
  const [addressVerification, setAddressVerification] = useState({
    loading: false,
    visible: false,
    data: [],
  })

  const [FirstName, FirstNameRendered, SetFirstName] = TextInput({
    validationVisible,
    labelText: "First name",
    required: true,
  })
  const [LastName, LastNameRendered, SetLastName] = TextInput({
    validationVisible,
    labelText: "Last name",
    required: true,
  })
  const [Company, CompanyRendered, SetCompany] = TextInput({
    validationVisible,
    labelText: "Company (optional)",
  })
  const [Address, AddressRendered, SetAddress] = TextInput({
    validationVisible,
    labelText: "Street address",
    required: true,
    helpText: "Please note: We cannot ship to post office boxes.",
  })
  const [Address2, Address2Rendered, SetAddress2] = TextInput({
    validationVisible,
    labelText: "Street address 2 (optional)",
    placeholderText: "Apartment or unit number",
  })
  const [City, CityRendered, SetCity] = TextInput({
    validationVisible,
    labelText: "City",
    required: true,
  })
  const [State, StateRendered, SetState] = StateInput({
    labelText: "State",
    required: true,
  })
  const [Zip, ZipRendered, SetZip] = TextInput({
    validationVisible,
    labelText: "Zip",
    validationPattern: "^[0-9]{5}(?:-[0-9]{4})?$",
    required: true,
  })
  const [Phone, PhoneRendered, SetPhone] = TextInput({
    validationVisible,
    labelText: "Phone number",
    required: true,
    validator: validatePhone,
    validationPattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
    placeholderText: "(555)555-5555",
  })

  const [Email, EmailRendered, SetEmail] = TextInput({
    validationVisible,
    labelText: "Email address",
    type: "email",
    required: true,
    placeholderText: "you@email.com",
    helpText: "We'll never sell or spam your email.",
  })

  useEffect(() => {
    const f = async () => {
      const result = await API.graphql(
        graphqlOperation(checkout, { Review: true })
      )

      /*
      const myOrders = await API.graphql(
        graphqlOperation(getMyOrders)
      );
      console.log({myOrders}, 'got my orders!');

      */

      // TODO: pass data to order summary

      console.log({ result }, "got cart!")
      SetCheckoutData(_.get(result, "data.Checkout"))
      const shipping = _.get(result, "data.Checkout.Cart.Shipping") || {}

      if (!_.isEmpty(shipping)) {
        SetFirstName(shipping.FirstName)
        SetLastName(shipping.LastName)
        SetCompany(shipping.Company)
        SetAddress(shipping.Address)
        SetAddress2(shipping.Address2)
        SetCity(shipping.City)
        SetState(shipping.State)
        SetZip(shipping.Zip)
        SetPhone(shipping.Phone)
        SetEmail(shipping.Email)
      }

      // const warrantyStates = await fetchWarrantyStates()
      // SetWarrantyStates({ loading: false, data: warrantyStates.WarrantyStates })
    }
    f()
  }, []) // OnLoad

  const renderAddr = (
    { Address, Address2, City, State, Zip, defaultChecked },
    index = 0
  ) => {
    const handleClick = () => {
      SetAddress(Address)
      SetAddress2(Address2)
      SetCity(City)
      SetState(State)
      SetZip(Zip)
      setChosenAddress(`${Address} ${Address2} ${City} ${State} ${Zip}`)
    }

    // console.log("defaultChecked: ", defaultChecked)
    return (
      <label
        key={index + 1}
        className={`address-verification ${
          chosenAddress === `${Address} ${Address2} ${City} ${State} ${Zip}`
            ? "checked"
            : ""
          }`}
      >
        <input
          type="radio"
          value={`${Address} ${Address2} ${City} ${State} ${Zip}`}
          name="verified-shipping-address"
          onClick={handleClick}
          defaultChecked={defaultChecked}
        />
        <p>{Address}</p>
        {Address2 != null ? <p>{Address2}</p> : ""}
        <p>
          {City}, {State} {Zip}
        </p>
      </label>
    )
  }

  const addressVerificationRendered = addressVerification.visible ? (
    <div className="grid-x grid-margin-x">
      <div className="cell small-12 medium-6">
        <fieldset>
          <legend className="header__small-caps">Address you entered</legend>
          {renderAddr({ ...addressAsEntered, defaultChecked: true })}
        </fieldset>
      </div>
      <div className="cell small-12 medium-6">
        <fieldset>
          <legend className="header__small-caps">
            Verified addresses found
          </legend>
          {addressVerification.loading ? (
            <b>Verifying Address......</b>
          ) : (
              _.map(addressVerification.data, renderAddr)
            )}
        </fieldset>
      </div>
    </div>
  ) : (
      undefined
    )

  const doValidateAddress = async event => {
    event.preventDefault()
    setSubmitState({ submitting: true })
    const validatePayload = {
      Address: {
        Address,
        Address2,
        City,
        State,
        Zip,
      },
    }
    setAddressAsEntered({
      Address,
      Address2,
      City,
      State,
      Zip,
    })
    setChosenAddress(`${Address} ${Address2} ${City} ${State} ${Zip}`)
    setAddressVerification({ data: [], visible: true, loading: true })

    const validateResult = await API.graphql(
      graphqlOperation(validateAddress, validatePayload)
    )
    // console.log({validateResult});
    setAddressVerification({
      data: validateResult.data.ValidateAddress,
      visible: true,
      loading: false,
    })
    setSubmitState({ submitting: false })
    openModal()
  }

  const submit = async event => {
    event.preventDefault()
    const fieldValues = [
      FirstName,
      LastName,
      Company,
      Address,
      Address2,
      City,
      State,
      Zip,
      Phone,
      Email,
      // shippingService,
      // DeliveryInstructions,
    ]
    let passedValidation = true
    _.map(fieldValues, value => {
      if (_.isUndefined(value)) {
        passedValidation = false
      }
    })
    if (!passedValidation) {
      setValidationVisible(true)
      return
    }

    const shippingPayload = {
      FirstName,
      LastName,
      Company,
      Address,
      Address2,
      City,
      State,
      Zip,
      Phone,
      Email,
    }
    setAddressSubmitState({ submitting: true })
    try {
      const result = await API.graphql(
        graphqlOperation(saveShippingAddress, shippingPayload)
      )
      console.log({ result }, "saved shipping information!")
      const Cart = result.data.Checkout.Cart
      const CheckoutDetails = _.pick(result.data.Checkout, [
        "Total",
        "Tax",
        "Subtotal",
      ])
      // navigate('/app/checkout/payment', {state: { Errors: _.get(result, 'data.Checkout.Errors') }});
      setAddressSubmitState({ submitting: false })
      navigate("/app/checkout/shipping", { state: result.data.Checkout })
    } catch (err) {
      setAddressSubmitState({ submitting: false })
      closeModal()
      const errMsgs = _.map(_.get(err, "errors"), ({ message }) => message)
      setSubmitError(errMsgs.join(", "))
      console.error({ err }, "got error!")
      // TODO: render error, let user try again
    }
    // navigate('/app/checkout/payment');
  }

  Modal.setAppElement("#___gatsby")

  const myOrders = useMyOrders()
  const handleUseLastOrderData = e => {
    e.preventDefault()

    const lastOrder = _.last(myOrders.data)
    const shipping = lastOrder.Shipping

    if (!_.isEmpty(shipping)) {
      SetFirstName(shipping.FirstName)
      SetLastName(shipping.LastName)
      SetCompany(shipping.Company)
      SetAddress(shipping.Address)
      SetAddress2(shipping.Address2)
      SetCity(shipping.City)
      SetState(shipping.State)
      SetZip(shipping.Zip)
      SetPhone(shipping.Phone)
      SetEmail(shipping.Email)
      setHidePrevAddress(true)
    }
  }

  const showPreviousAddress = () => {
    const lastOrder = _.last(myOrders.data)
    const shipping = lastOrder.Shipping
    return (
      <div className="user-info-summary__previous-order">
        <p>
          {shipping.FirstName} {shipping.LastName}
        </p>
        {shipping.Company && <p>{shipping.Company}</p>}
        <p>{shipping.Address}</p>
        {shipping.Address2 && <p>{shipping.Address2}</p>}
        <p>
          {shipping.City}, {shipping.State} {shipping.Zip}
        </p>
        <p>
          <i className="fi-telephone" /> {shipping.Phone}
        </p>
        <p>
          <i className="fi-mail" /> {shipping.Email}
        </p>
      </div>
    )
  }

  const previousOrdersRendered =
    _.size(myOrders.data) === 0 ? (
      undefined
    ) : (
        <div
          className={`cell small-12 callout primary ${
            hidePrevAddress ? "hide" : undefined
            }`}
        >
          <p>
            <strong>You used this address last time you ordered.</strong>
          </p>
          {showPreviousAddress()}
          <Button
            style="secondary"
            onClickHandler={handleUseLastOrderData}
            text="Use this address"
          />
        </div>
      )

  return (
    <Fragment>
      <div className="grid-x grid-margin-x">
        <form onSubmit={doValidateAddress} className="cell small-12 medium-8">
          <fieldset className="grid-x grid-padding-x fieldset--center-legend">
            <legend className="header__small-caps">Shipping address</legend>
            {previousOrdersRendered}
            <div className="cell small-12 medium-6">{FirstNameRendered}</div>
            <div className="cell small-12 medium-6">{LastNameRendered}</div>
            <div className="cell small-12">
              {CompanyRendered}
              {AddressRendered}
              {Address2Rendered}
            </div>
            <div className="cell small-8">{CityRendered}</div>
            <div className="cell small-4">{StateRendered}</div>
            <div className="cell small-12 medium-6">{ZipRendered}</div>
          </fieldset>
          <fieldset className="grid-x grid-padding-x fieldset--center-legend">
            <legend className="header__small-caps">
              Shipping contact information
            </legend>
            <div className="cell small-12 medium-6">{PhoneRendered}</div>
            <div className="cell small-12">{EmailRendered}</div>
          </fieldset>
          <fieldset className="grid-x grid-padding-x">
            <Button
              isDisabled={submitState.submitting}
              style={
                submitState.submitting
                  ? "primary expanded loading"
                  : "primary expanded"
              }
              type="submit"
              text="Choose shipping options"
            />
            <br />
            {submitError}
          </fieldset>
        </form>
        <div className="cell small-12 medium-4">
          <CartCheckoutOrderSummary
            ShippingPrice={
              _.get(
                CheckoutData,
                "Cart.ShippingService.shippingService.Price"
              ) || 0
            }
            Subtotal={_.get(CheckoutData, "Subtotal") || 0}
            Tax={_.get(CheckoutData, "Tax") || 0}
            Total={_.get(CheckoutData, "Total") || 0}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Address verification modal"
        className="modal"
      >
        <h3>Choose an address</h3>
        <p>
          We found multiple addresses matching the information you entered.
          Please choose one of the following options.
        </p>
        {addressVerificationRendered}
        <Button
          isDisabled={addressSubmitState.submitting}
          style={
            addressSubmitState.submitting ? "secondary expanded loading" : "secondary expanded"
          }
          onClickHandler={submit}
          text="Use this address"
        />
      </Modal>
    </Fragment>
  )
}

export default CartCheckoutShippingAddress
