import _ from 'lodash';
import React, { useState, useEffect, useContext } from 'react';
import { graphqlOperation, API }  from 'aws-amplify';
import { saveBillingPayment } from '../../graphql/cart';
import { navigate } from 'gatsby';
import CartOrderSummary from './CartOrderSummary';
import { CartContext } from '../CartContext'
import { formatCurrency } from '../../utils/cart';
import CartCheckoutPayment from './CartCheckoutPayment';
import CartCheckoutShipping from './CartCheckoutShipping';
import CartCheckoutBilling from './CartCheckoutBilling';
import SEO from "../seo";
import { Link } from "gatsby"

const CartCheckoutOrderSummary = ({Subtotal, Tax, Total, ShippingPrice }) => (<div className="cell small-12 medium-4 order-summary--container__sticky">
<div className="grid-x grid-padding-x grid-padding-y order-summary--container">
  <div className="cell small-12">
    <h4 className="header__small-caps">Order summary</h4>
    <p className="order-summary--item">
      Subtotal:{" "}
      <span className="currency">{formatCurrency(Subtotal)}</span>
    </p>
    <p className="order-summary--item">
      Estimated tax:{" "}
      <span className="currency">{formatCurrency(Tax)}</span>
    </p>
    <p className="order-summary--item">
      Estimated shipping:
<span className="currency">{formatCurrency(ShippingPrice)}</span>
    </p>

    <p className="order-summary--item">
      <strong>
        Estimated total:{" "}
        <span className="currency">{formatCurrency(Total)}</span>
      </strong>
    </p>

  </div>
</div>
</div>);

export default CartCheckoutOrderSummary;