import _ from "lodash"
import React, { useState, useEffect, useContext } from "react"
import { graphqlOperation, API } from "aws-amplify"
import TextInput, { validateNone, validatePhone } from "../TextInput"
import { navigate } from "gatsby"
import CartOrderSummary from "./CartOrderSummary"
import { saveBilling, getCart } from "../../graphql/cart"
import CheckboxInput from "../CheckboxInput"
import StateInput from "../StateInput"
import { CartContext } from "../CartContext"
import { useMyOrders } from "./hooks"
import Button from "../Button"

const CartCheckoutBilling = checkoutState => {
  const { Cart, CheckoutClauses } = useContext(CartContext)
  const { PlugtrayCount } = Cart

  const [hidePrevAddress, setHidePrevAddress] = useState(undefined)

  const [validationVisible, setValidationVisible] = useState(false)

  const [IsSame, IsSameRendered] = CheckboxInput({
    validationVisible,
    labelText: "Billing information is the same as shipping information",
  })

  const [FirstName, FirstNameRendered, SetFirstName] = TextInput({
    validationVisible,
    labelText: "First name",
    required: true,
  })
  const [LastName, LastNameRendered, SetLastName] = TextInput({
    validationVisible,
    labelText: "Last name",
    required: true,
  })
  const [Company, CompanyRendered, SetCompany] = TextInput({
    validationVisible,
    labelText: "Company (optional)",
  })

  const [Address, AddressRendered, SetAddress] = TextInput({
    validationVisible,
    labelText: "Address",
    required: true,
  })

  const [Address2, Address2Rendered, SetAddress2] = TextInput({
    validationVisible,
    labelText: "Address 2 (optional)",
    placeholderText: "Apartment or unit number",
  })
  const [City, CityRendered, SetCity] = TextInput({
    validationVisible,
    labelText: "City",
    required: true,
  })
  const [State, StateRendered, SetState] = StateInput({
    labelText: "State",
    required: true,
  })

  const [Zip, ZipRendered, SetZip] = TextInput({
    validationVisible,
    labelText: "Zip",
    validationPattern: "^[0-9]{5}(?:-[0-9]{4})?$",
    required: true,
  })
  const [Phone, PhoneRendered, SetPhone] = TextInput({
    validationVisible,
    labelText: "Phone number",
    required: true,
    validator: validatePhone,
    validationPattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
    placeholderText: "(555)555-5555",
  })

  const [Email, EmailRendered, SetEmail] = TextInput({
    validationVisible,
    labelText: "Email address",
    type: "email",
    required: true,
    placeholderText: "you@email.com",
    helpText: "We'll never sell or spam your email.",
  })

  useEffect(() => {
    const f = async () => {
      const billing = _.get(checkoutState, "data.Cart.Billing")
      if (billing) {
        SetFirstName(billing.FirstName)
        SetLastName(billing.LastName)
        SetCompany(billing.Company)
        SetAddress(billing.Address)
        SetAddress2(billing.Address2)
        SetCity(billing.City)
        SetState(billing.State)
        SetZip(billing.Zip)
        SetPhone(billing.Phone)
        SetEmail(billing.Email)
      }
    }
    f()
  }, [checkoutState.data])

  useEffect(() => {
    const {
      FirstName,
      LastName,
      Company,
      Address,
      Address2,
      City,
      State,
      Zip,
      Phone,
      Email,
    } = _.get(CheckoutClauses, "Shipping") || {}
    SetFirstName(IsSame ? FirstName : "")
    SetLastName(IsSame ? LastName : "")
    SetCompany(IsSame ? Company : "")
    SetAddress(IsSame ? Address : "")
    SetAddress2(IsSame ? Address2 : "")
    SetCity(IsSame ? City : "")
    SetState(IsSame ? State : "")
    SetZip(IsSame ? Zip : "")
    SetPhone(IsSame ? Phone : "")
    SetEmail(IsSame ? Email : "")
  }, [IsSame])

  const validateForm = () => {
    const fieldValues = [
      FirstName,
      LastName,
      Address,
      Address2,
      City,
      State,
      Zip,
      Phone,
      Email,
    ]
    let passedValidation = true
    _.map(fieldValues, value => {
      if (_.isUndefined(value)) {
        passedValidation = false
      }
    })
    if (!passedValidation) {
      setValidationVisible(true)
    }
    return passedValidation
  }

  const myOrders = useMyOrders()
  const handleUseLastOrderData = e => {
    e.preventDefault()

    const lastOrder = _.last(myOrders.data)
    const billing = lastOrder.Billing
    if (billing) {
      SetFirstName(billing.FirstName)
      SetLastName(billing.LastName)
      SetCompany(billing.Company)
      SetAddress(billing.Address)
      SetAddress2(billing.Address2)
      SetCity(billing.City)
      SetState(billing.State)
      SetZip(billing.Zip)
      SetPhone(billing.Phone)
      SetEmail(billing.Email)
      setHidePrevAddress(true)
    }
  }

  const showPreviousAddress = () => {
    const lastOrder = _.last(myOrders.data)
    const billing = lastOrder.Billing
    return (
      <div className="user-info-summary__previous-order">
        <p>
          {billing.FirstName} {billing.LastName}
        </p>
        {billing.Company && <p>{billing.Company}</p>}
        <p>{billing.Address}</p>
        {billing.Address2 && <p>{billing.Address2}</p>}
        <p>
          {billing.City}, {billing.State} {billing.Zip}
        </p>
        <p>
          <i className="fi-telephone" /> {billing.Phone}
        </p>
        <p>
          <i className="fi-mail" /> {billing.Email}
        </p>
      </div>
    )
  }

  const previousOrdersRendered =
    _.size(myOrders.data) === 0 ? (
      undefined
    ) : (
        <div
          className={`cell small-12 callout primary ${
            hidePrevAddress ? "hide" : undefined
            }`}
        >
          <p>
            <strong>You used this address last time you ordered.</strong>
          </p>
          {showPreviousAddress()}
          <Button
            style="secondary"
            onClickHandler={handleUseLastOrderData}
            text="Use this address"
          />
        </div>
      )

  const rendered = (
    <>
      <fieldset className="grid-x grid-padding-x fieldset--center-legend">
        <legend className="header__small-caps">Billing address</legend>
        {previousOrdersRendered}
        <div className="cell small-12">{IsSameRendered}</div>
        <div className="cell small-12 medium-6">{FirstNameRendered}</div>
        <div className="cell small-12 medium-6">{LastNameRendered}</div>
        <div className="cell small-12">
          {CompanyRendered}
          {AddressRendered}
          {Address2Rendered}
        </div>
        <div className="cell small-8">{CityRendered}</div>
        <div className="cell small-4">{StateRendered}</div>
        <div className="cell small-12 medium-6">{ZipRendered}</div>
      </fieldset>
      <fieldset className="grid-x grid-padding-x fieldset--center-legend">
        <legend className="header__small-caps">
          Billing contact information
        </legend>
        <div className="cell small-12  medium-6">{PhoneRendered}</div>
        <div className="cell small-12">{EmailRendered}</div>
      </fieldset>
    </>
  )

  const formPayload = {
    FirstName,
    LastName,
    Company,
    Address,
    Address2,
    City,
    State,
    Zip,
    Phone,
    Email,
  }
  return [rendered, formPayload, validateForm]
}

export default CartCheckoutBilling
