import React from "react"
import { Link } from "gatsby"

const UserInfoSummary = props => {
  const { heading, editLink, infoRendered } = props
  return (
    <div className="user-info-summary grid-x">
      <div className="cell small-10">
        <h1 className="header__small-caps">
          <span>{heading}</span>
        </h1>
        {infoRendered}
      </div>
      <p className="user-info-summary--edit cell small-2">
        <Link to={editLink}>
          <i className="fi-pencil"></i> Edit{" "}
          <span className="show-for-sr">{heading}</span>
        </Link>
      </p>
    </div>
  )
}

export default UserInfoSummary
