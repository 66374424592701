import _ from "lodash"
import React, { useState, Fragment } from "react"
import TextInput, {
  validateCreditCardNumber,
  validateExpirationDate,
  validateCVV,
} from "../TextInput"

const translateError = err => {
  if (_.includes(err, "(E00114)")) {
    return "Your credit card expired (we only save it for 15 minutes for security reasons).  Please re-enter your card and try again."
  }
  return err
}

const CartCheckoutPayment = ({ location }) => {
  const Errors = _.map(_.get(location, "state.Errors") || [], translateError)
  const [validationVisible, setValidationVisible] = useState(false)

  const [CreditCardNumber, CreditCardRendered] = TextInput({
    validationVisible,
    validator: validateCreditCardNumber,
    labelText: "Credit card number",
  })
  const [ExpirationDate, ExpirationDateRendered] = TextInput({
    validationVisible,
    validator: validateExpirationDate,
    labelText: "Expiration date",
    placeholderText: "MM/YY",
    helpText: "MM/YY",
  })
  const [SecurityCode, SecurityCodeRendered] = TextInput({
    validationVisible,
    validator: validateCVV,
    labelText: "Security code",
    placeholderText: "000",
  })

  const validateForm = () => {
    const fieldValues = [CreditCardNumber, ExpirationDate, SecurityCode]
    let passedValidation = true
    _.map(fieldValues, value => {
      if (_.isUndefined(value)) {
        passedValidation = false
      }
    })
    if (!passedValidation) {
      setValidationVisible(true)
      return false
    }
    return true
  }

  let renderedErrors
  if (_.size(Errors) > 0) {
    const errs = _.map(Errors, (error, index) => <p key={index}>{error}</p>)
    renderedErrors = (
      <div className="callout alert">
        <h4>Your order could not be completed.</h4>
        {errs}
      </div>
    )
  }

  const rendered = (
    <>
      {renderedErrors}
      <fieldset className="grid-x grid-padding-x fieldset--center-legend">
        <legend className="header__small-caps">Payment Information</legend>
        <div className="cell small-12 medium-6">{CreditCardRendered}</div>
        <div className="cell small-6 medium-3">{ExpirationDateRendered}</div>
        <div className="cell small-4 medium-3">{SecurityCodeRendered}</div>
      </fieldset>
    </>
  )

  const payload = { CreditCardNumber, ExpirationDate, SecurityCode }
  return [rendered, payload, validateForm]
}

export default CartCheckoutPayment
