import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { Auth } from "aws-amplify"
import { logout } from "../../utils/auth"
import { navigate } from "gatsby"
import _ from "lodash"
import { Link } from "gatsby"
import { UserContext } from "../UserContext"
import LoginModal from "./LoginModal"
import CartBannerWidget from "../cart/CartBannerWidget"


const User = ({ location }) => {
  const { User, refreshUser, logoutUser } = useContext(UserContext)
  const [LoginModalRendered, setModalOpen] = LoginModal()
  const handleLoginClick = _.partial(setModalOpen, true)

  let links = [CartBannerWidget()]
  if (_.get(User, "username")) {
    links.push(
      <li key="logout">
        <a onClick={logoutUser}>
        <i className="fi-eject" />
          Logout
        </a>

      </li>
    )
    links.push(
      <li key="profile">
        <Link to="/app/profile">
          <i className="fi-address-book" />
          Profile
        </Link>
      </li>
    )
  } else {
    links.push(
      <li key="login">
        <a onClick={handleLoginClick}>
          <i className="fi-torso" />
          Log in
        </a>
      </li>
    )
  }
  if (_.get(User, "isAdmin")) {
    links.push(
      <li key="admin">
        <Link to="/app/admin">
          <i className="fi-lock" />
          Admin Dashboard
        </Link>
      </li>
    )
  }

  return (
    <div>
      {LoginModalRendered}
      <ul className="menu dropdown">{links}</ul>
    </div>
  )
}

export default User
