import fetch from 'isomorphic-fetch';

const PUBLIC_API_ROOT = 'https://api.acorn.dev.plugtrays.com/public';

export const fetchWarrantyStates = async () => {
    const result = await fetch(`https://www.acorn.dev.plugtrays.com/warranty-states.json`);
    if (result.states >= 400) {
        throw new Error("Bad response from server");
    }
    const resultJson = await result.json();
    return resultJson;
};
