export const saveBillingPayment = `
mutation saveCartBillingPayment ($FirstName:String!, $LastName:String!, $Address:String!, $Address2:String, $City:String!, $State:String!, $Zip:String!, $Phone:String!, $Email:String!, $Token:String!, $CreateAccountPassword:String) {
  SaveCart(Billing: {
    FirstName: $FirstName,
    LastName: $LastName,
    Address: $Address,
    Address2: $Address2,
    City: $City,
    State: $State,
    Zip: $Zip,
    Phone: $Phone,
    Email: $Email
  }, Payment: {
    Token: $Token
  }, CreateAccount: {
    Password: $CreateAccountPassword
  }) {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
    CreateAccount {
      Password
    }
  }
}
`

export const saveBilling = `
mutation saveCartBilling ($FirstName:String!, $LastName:String!, $Address:String!, $Address2:String, $City:String!, $State:String!, $Zip:String!, $Phone:String!, $Email:String! ) {
  SaveCart(Billing: {
    FirstName: $FirstName,
    LastName: $LastName,
    Address: $Address,
    Address2: $Address2,
    City: $City,
    State: $State,
    Zip: $Zip,
    Phone: $Phone,
    Email: $Email
  }) {
    Billing {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
    }
  }
}
`

export const saveShippingAddress = `
mutation saveCartShippingAddress ($FirstName:String!, $LastName:String!, $Company:String, $Address:String!, $Address2:String, $City:String!, $State:String!, $Zip:String!, $Phone:String!, $Email:String!) {
  SaveCart(Shipping: {
    FirstName: $FirstName,
    LastName: $LastName,
    Company: $Company,
    Address: $Address,
    Address2: $Address2,
    City: $City,
    State: $State,
    Zip: $Zip,
    Phone: $Phone,
    Email: $Email
  }) {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
  }

  Checkout (Review: true) {
    OrderId
    Errors
    Status
    Message
    Total
    Tax
    Subtotal
    Cart {
      Products {
        Id
        Quantity
        Status
        QuantityValidated
        NamePlugtrays
        PricePlugtrays
      }
      Shipping {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Billing {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Payment {
        Token
      }
      ShippingService {
        shippingService {
          DaysInTransit
          DeliveryTime
          Price
          ServiceCode
        }
      }
    }
  }
}
`

export const saveShipping = `
mutation saveCartShipping ($DaysInTransit:String, $DeliveryTime:String, $Price:Float, $ServiceCode:String, $DeliveryInstructions:String, $IsGift:Boolean, $GiftMessage:String, $WaiveWarranty:Boolean) {
  SaveCart(ShippingService: {
    shippingService: {
      DaysInTransit: $DaysInTransit,
      DeliveryTime: $DeliveryTime,
      Price: $Price,
      ServiceCode: $ServiceCode
    },
    DeliveryInstructions: $DeliveryInstructions,
    IsGift: $IsGift,
    GiftMessage: $GiftMessage,
    WaiveWarranty: $WaiveWarranty
  }) {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
  }
  Checkout (Review: true) {
    OrderId
    Errors
    Status
    Message
    Total
    Tax
    Subtotal
    Cart {
      Products {
        Id
        Quantity
        Status
        QuantityValidated
        NamePlugtrays
        PricePlugtrays
      }
      Shipping {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Billing {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Payment {
        Token
      }
      ShippingService {
        shippingService {
          DaysInTransit
          DeliveryTime
          Price
          ServiceCode
        }
      }
    }
  }
}
`

export const savePayment = `
mutation saveCartPayment ($Token:String!) {
  SaveCart(Payment: {
    Token: $Token
  }) {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
  }
}
`

export const createProductNotification = `
mutation createProductNotification($ProductId: String!, $Email: String!) {
  CreateProductNotification(ProductId: $ProductId, Email: $Email)
}
`

export const saveProducts = `
mutation saveCartProducts($Products: [CartProductInput]) {
  SaveCart(Products: $Products) {
    Products {
      Id
      Quantity
    }
  }
}
`

export const getCart = `
query GetCart {
  GetCart {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
  }
}
  
`

export const getCartProductsAndShipping = `
query GetCart {
  GetCart {
    Products {
      Id
      Quantity
    }
    Shipping {
      FirstName
      LastName
      Company
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
    }
  }
}
  
`

export const checkout = `
mutation checkout ($Review: Boolean!){
  Checkout (Review: $Review) {
    OrderId
    Errors
    Status
    Message
    Total
    Tax
    Subtotal
    Cart {
      Products {
        Id
        Quantity
        Status
        QuantityValidated
        CommonName
        BotanicalName
        NamePlugtrays
        PricePlugtrays
      }
      Shipping {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Billing {
        FirstName
        LastName
        Address
        Address2
        City
        State
        Zip
        Phone
        Email
      }
      Payment {
        Token
      }
      ShippingService {
        shippingService {
          DaysInTransit
          DeliveryTime
          Price
          ServiceCode
        }
      }
    }
  }
}`


export const getOrder = `
query getOrder ($Id:String!) {
  GetOrder(Id: $Id) {
    Products {
      Id
      Quantity
      Status
      QuantityValidated
      PricePlugtrays
      NamePlugtrays
    }
    Shipping {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    ShippingService {
      DeliveryInstructions
      IsGift
      GiftMessage
      WaiveWarranty
      shippingService {
        DaysInTransit
        DeliveryTime
        Price
        ServiceCode
      }
    }
    Billing {
      FirstName
      LastName
      Address
      Address2
      City
      State
      Zip
      Phone
      Email
    }
    Payment {
      Token
    }
    Id
    PaymentAuthorization {
      ResponseCode
    }
    SourceIp
    Status
    Subtotal
    Tax
    TimestampCreated
    Total
    UserId
  }
}
`


export const validateAddress = `
query validateAddress ($Address:AddressInput!) {
  ValidateAddress(Address: $Address) {
    Address
    Address2
    City
    State
    Zip
  }
}`

export const getShippingOptions = `
query getShippingOptions ($Address:GetShippingAddressInput!, $PlugtrayCount:Int!) {
  GetShippingOptions(Address: $Address, PlugtrayCount: $PlugtrayCount) {
    DaysInTransit
    DeliveryTime
    Price
    PriceCurrency
    ServiceCode
    BillingWeight
  }
}`

export const saveWarrantyStates = `
mutation saveWarrantyStates($States: [String]) {
  SaveWarrantyStates(States: $States)
}
`

export const loginMergeIdentity = `
mutation loginMergeIdentity($UserId: String!) {
  LoginMergeIdentity(UserId: $UserId)
}
`
