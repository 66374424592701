import React from "react"

const Button = props => {
  const { style, type, onClickHandler, text, isDisabled } = props
  return (
    <button
      className={`button ${style || "primary"}`}
      type={`${type || "button"}`}
      onClick={onClickHandler}
      disabled={isDisabled}
    >
      <span>{text}</span>
    </button>
  )
}

export default Button
