import _ from "lodash"
import { fetchProductsById } from "../utils/public-api"
import slugify from "slugify"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
})

export const serviceCodeLookup = {
  "02": "UPS 2nd-Day Air",
  "03": "UPS Ground",
}

export const getProductLink = (product) => `/organic-plants/${product.Slug}`;

export const formatCurrency = value => formatter.format(value || 0)

export const isBrowser = () => typeof window !== "undefined"

// Predicate gets called with (quantity, key)
export const hydrateCartProductsWithFn = async (cartProducts, predicate) => {
  const productIds = _.keys(cartProducts)
  const result = await fetchProductsById(productIds)
  const newApiProducts = _.keyBy(result.Products, ({ Id }) => Id)

  _.map(cartProducts, (quantity, key) => {
    if (!_.has(newApiProducts, key)) {
      console.error(
        { quantity, key },
        "could not find product in API response that's in the cart!"
      )
      return
    }
    _.set(newApiProducts, [key, "cartQuantity"], quantity)
    if (_.isFunction(predicate)) {
      _.set(newApiProducts, key, {
        ..._.get(newApiProducts, key),
        ...predicate(quantity, key),
      })
    }
  })
  return newApiProducts
}

export const calculateOrderDetails = cartProducts => {
  const plugtrayCount = _.sum(
    _.map(cartProducts, product => product.cartQuantity)
  )
  const packageCount = _.ceil(plugtrayCount * 0.5)
  const subtotal = _.sum(
    _.map(
      cartProducts,
      product => product.cartQuantity * product.PricePlugtrays
    )
  )
  return {
    plugtrayCount,
    packageCount,
    subtotal,
  }
}

export const slugifyProductId = Id => _.toLower(slugify(Id))
