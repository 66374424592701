import fetch from 'isomorphic-fetch';

const PUBLIC_API_ROOT = 'https://api.acorn.dev.plugtrays.com/public';

export const fetchPricesForTag = (tag) => {
    return fetch(`${PUBLIC_API_ROOT}?type=tag&tag=${encodeURIComponent(tag)}`)
    .then((result) => {
      if (result.status >= 400) {
        throw new Error("Bad response from server");
      }
      return result.json();
    });
  };

export const fetchProductsById = (ids) => {
    // ids is an array of product ids
    if (_.size(ids) < 1) {
      return [];
    }
    return fetch(`${PUBLIC_API_ROOT}?type=id&ids=${encodeURIComponent(ids)}`)
        .then((result) => {
            if (result.status >= 400) {
              throw new Error("Bad response from server");
            }
            return result.json();
        });
}

export const searchProducts = async (query) => {
  const result = await fetch(`${PUBLIC_API_ROOT}?type=search&query=${encodeURIComponent(query)}`);
  if (result.status >= 400) {
    throw new Error(`Bad response ${result.status} from server.`);
  }
  const resultJson = await result.json();
  // console.log({resultJson}, 'got result');
  return resultJson;
};