import _ from "lodash"
import React, { useState, useEffect } from "react"
import { getShippingOptions } from "../../graphql/cart"
import { graphqlOperation, API } from "aws-amplify"
import { serviceCodeLookup } from "../../utils/cart"

const ShippingServiceInput = ({
  PlugtrayCount,
  FirstName,
  LastName,
  Address,
  Address2,
  City,
  State,
  Zip,
}) => {
  const [shippingOptions, setShippingOptions] = useState({
    loading: true,
    data: [],
  })

  const [shippingChoice, setShippingChoice] = useState(undefined)

  useEffect(() => {
    const f = async () => {
      if (_.size(_.trim(Zip)) < 5) {
        return
      }
      const AddressInput = {
        FirstName,
        LastName,
        Address,
        Address2,
        City,
        State,
        Zip,
      }
      try {
        const shipping = await API.graphql(
          graphqlOperation(getShippingOptions, {
            Address: AddressInput,
            PlugtrayCount,
          })
        )

        setShippingOptions({
          loading: false,
          data: shipping.data.GetShippingOptions,
        })
        // console.log({shipping}, 'got shipping');
        // TODO: address some missing fields
      } catch (err) {
        console.error({ err })
      }
    }
    f()
  }, [PlugtrayCount, Zip])

  const renderOption = shippingOption => {
    const { Price, ServiceCode } = shippingOption
    const handleChange = () => {
      setShippingChoice(shippingOption)
    }

    return (
      <label key={ServiceCode}>
        <input
          type="radio"
          name="shippingOption"
          value={ServiceCode}
          onChange={handleChange}
          required
        />
        {`${serviceCodeLookup[ServiceCode]} - $${Price}`}
      </label>
    )
  }

  let renderedOptions = shippingOptions.loading
    ? [<option key="placeholder">Please enter your zip</option>]
    : _.map(shippingOptions.data, renderOption)

  const rendered = (
    <div className="cell small-12">
      <p>
        <strong>Shipping options</strong>
      </p>
      <p>
        In order to get your plants to you in top condition, we ship most plugs
        UPS 2nd-Day Air. Some areas may be eligible for UPS Ground shipping.
      </p>
      {renderedOptions}
    </div>
  )

  return [shippingChoice, rendered, setShippingChoice]
}

export default ShippingServiceInput
