import React, { useState, useEffect, createContext } from 'react';
import { getUser, getIdentityId } from '../utils/auth';
import store from 'store';
import { Auth } from "aws-amplify"
import { logout } from "../utils/auth"



const GUEST_IDENTITY_STORAGE_KEY = 'guestIdentityId';
const UserContext = createContext([{}, () => {}]);

const UserProvider = (props) => {
    const [User, setUser] = useState(undefined);

    const refreshUser = async () => {
      const user = await getUser();
      setUser(user);
      if (!user) {
        const IdentityId = await getIdentityId();
        store.set(GUEST_IDENTITY_STORAGE_KEY, IdentityId);
      }
    };

    const logoutUser = async () => {
      try {
      let signoutResult = await Auth.signOut({global: true});
      } catch (err) {
        console.error({err}, 'encountered an error doing logout, maybe you\'re already logged out');
      }
      logout();
      refreshUser();
    }

    useEffect(() => {
        refreshUser();
    }, []);
    return (
      <UserContext.Provider value={{User, refreshUser, logoutUser}}>
        {props.children}
      </UserContext.Provider>
    );
};
  
export { UserContext, UserProvider };